import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p>
        <span>&copy;</span>2023 All right reserved
      </p>
    </div>
  );
}

export default Footer;
